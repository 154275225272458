body {
    background-color: #282A36;
}

.ide-container {
    width: 100%;
    background-color: #282A36;
    overflow: hidden;
}

.ide-toolbar {
    display: flex;
    justify-content: flex-end;
    background-color: #444;
    padding: 0.3rem;
}

.toolbar-btn {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-left: 5px;
}

.close-btn {
    background-color: #ff5f56;
}

.minimize-btn {
    background-color: #ffbd2e;
}

.maximize-btn {
    background-color: #27c93f;
}

.code-editor {
    padding: 10px;
    font-family: monospace;
}

.code-line {
    color: #abb2bf;
    margin-bottom: 1rem;
}

.code-line .code-keyword {
    color: #FF79C6;
}

.code-line .code-struct {
    color: #FF79C6;
}

.code-line .code-string {
    color: #50FA7B;
}

.code-line .code-variable {
    color: #FFB86C;
}

.code-line .code-comment {
    color: #6272A4;
}

.code-line .code-ident {
    color: #FF5555;
}