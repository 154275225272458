.credit {
  text-align: center;
  padding: 1rem 0rem 2rem 0rem;
  color: rgb(143, 143, 143);
}

.credit-text {
  display: inline-block;
  text-decoration: none;
  border: none;
  margin: 0;
  padding: 0;
  background: none;
  cursor: pointer;
  color: rgb(143, 143, 143);
}

.kofi-icon {
    width: 2rem;
    vertical-align: middle;

}