html {
  min-height: 100%;
}

.my-component-body {
  height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background: linear-gradient(55deg, #f5e7ea 0%, #ffc3d8 50%, #ffa0b6 99%);
}

.content {
  padding-top: 3rem;
  margin: auto;
  width: 50%;
  text-align: center;
}

@media (max-width: 768px) { 
  .content {
    width: 90%;
  }
}

.logo {
  width: 10rem;
}

.heading {
  font-size: 3rem;
  white-space: nowrap;
}

.text, .segment-text {
  padding: 2rem 0rem 2rem 0rem;
  text-align: justify;
  width: 90%;
  margin: auto;
}

.discord-button {
  align-content: center !important;
  background-color: #ff23b6;
  color: #ffffff;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.3s ease;
  text-decoration: none;
}

.discord-button:hover {
  background-color: #5865F2;
  cursor: pointer;
}

.segment-header {
  text-decoration: none;
  text-align: left;
  margin: auto;
  width: 90%;
  font-size: 1.2rem;
  padding-bottom: 1rem;
  font-weight: bold;
}

.segment-text{
  padding-top: 0.2rem;
  color: #4e5d94;
}