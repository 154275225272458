.terminal {
  background-color: #1a1a1a;
  border-radius: 6px;
  font-family: Consolas, monospace;
  height: auto;
  padding: 0.5rem;
  color: white;
  width: 90%;
  margin: auto;
  text-align: left;
  -webkit-box-shadow: 10px 10px 21px -14px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 21px -14px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 21px -14px rgba(0,0,0,0.75);

}

.terminal-prompt {
  color: #8aff8a;
  display: inline-block;
  margin-right: 5px;
}
