.background{
    background: rgb(251, 243, 243);
}

.nameHeader{
    text-align: center;
    text-overflow: ellipsis;
    font-size: xx-large;
    font-weight: 500;
}

.textDescription{
    text-align: center;
    text-overflow: ellipsis;
    margin: 1rem;
}

.container {
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#eeaeca",endColorstr="#94bbe9",GradientType=1);
    width: 80%;
    margin: auto;
    align-items: center;
    border: 20px;
    border-radius: 20px;
    padding: 1rem;
}

.header{
    text-align: center;
    text-overflow: ellipsis;
    font-size: x-large;
    padding: 3rem 0rem 3rem 0rem;
}

.profilePicture{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 20%;
    max-width: 12rem;
    min-width: 6rem;
    border-radius: 50%;
    box-shadow: 10px 11px 18px -1px rgba(0,0,0,0.38);
    margin-bottom: 1.5rem;
}
.iconContainer {
    display: flex;
    justify-content: center; /* Align buttons horizontally in the center */
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 1rem;
}

.iconButton {
    vertical-align: middle;
    width: 4rem;
    height: 4rem;
    text-align: center;
    border: 1px solid rgb(162, 162, 162);
    margin: 0.5rem;
    border-radius: 10px;
    --c:  #f1d4c760;
  
    box-shadow: 0 0 0 .1em inset var(--c); 
    --_g: linear-gradient(var(--c) 0 0) no-repeat;
    background: 
      var(--_g) calc(var(--_p,0%) - 100%) 0%,
      var(--_g) calc(200% - var(--_p,0%)) 0%,
      var(--_g) calc(var(--_p,0%) - 100%) 100%,
      var(--_g) calc(200% - var(--_p,0%)) 100%;
    background-size: 50.5% calc(var(--_p,0%)/2 + .5%);
    outline-offset: .1em;
    transition: background-size .4s, background-position 0s .4s;
}


.iconButton:hover {
    --_p: 100%;
    transition: background-position .4s, background-size 0s;
}

.iconButton:active {
    box-shadow: 0 0 9e9q inset #e6a4b59f; 
    background-color: var(--c);
    color: #fff;
  }

.iconImage{
    display: block;
    width: 60%;
    max-width: 5rem;
    min-width: 1rem;
    margin-left: auto;
    margin-right: auto;
}